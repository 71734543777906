import { Connection, Orientation, Piece, Position, Rotation, Tile } from "./types"

export const getPositionId = (x: number, y: number) => `${x},${y}`

export const flipCoordinate = (position: Position, rows: number, cols: number, orientation: Orientation): Position => {
  // (x, y) after flipping becomes this new (x, y)
  if (orientation === "h") {
    return { x: position.x, y: (rows - 1) - position.y };
  } else if (orientation === "v") {
    return { x: position.x, y: position.y };
    // return { x: (cols - 1) - position.x, y: position.y };
  }

  throw new Error(`Invalid orientation passed: ${orientation}`);
}

export const rotateCoordinate = (position: Position, rows: number, cols: number, degree: Rotation): Position => {
  // (x, y) after flipping becomes this new (x, y)
  // Rotates anti-clockwise

  const { x, y } = position;

  if (degree === 0) {
      return { x, y };
  }

  if (degree === 90) {
      return { x: y, y: cols - 1 - x };
  }

  if (degree === 180) {
      return { x: cols - 1 - x, y: rows - 1 - y };
  }

  if (degree === 270) {
      return { x: rows - 1 - y, y: x };
  }

  throw new Error(`Invalid value for degree: ${degree}`);
}

export const flipPiece = (piece: Piece, orientation: Orientation): Piece => {
  const rows = piece.grid.length;
  const cols = piece.grid[0].length;

  // Flip grid
  const flippedGrid: Tile[][] = Array.from({ length: rows }, () => Array(cols).fill(null));
  for (let y = 0; y < rows; y++) {
      for (let x = 0; x < cols; x++) {
          // (x, y) after flipping becomes flipped_coordinate
          const flippedCoordinate = flipCoordinate({ x, y }, rows, cols, orientation);
          flippedGrid[flippedCoordinate.y][flippedCoordinate.x] = piece.grid[y][x];
      }
  }

  // Flip connections
  const flippedConnections: Connection[] = [];
  for (const connection of piece.connections) {
      const tiles: Position[] = [];
      for (const position of connection.tiles) {
          // (x, y) after flipping becomes flipped_coordinate
          const flippedCoordinate = flipCoordinate(position, rows, cols, orientation);
          tiles.push(flippedCoordinate);
      }
      flippedConnections.push({ tiles });
  }

  return { ...piece, grid: flippedGrid, connections: flippedConnections };
}

export const rotatePiece = (piece: Piece, degree: Rotation): Piece  => {
  const rows = piece.grid.length;
  const cols = piece.grid[0].length;

  // Find new grid size
  const corners: [number, number][] = [
      [0, 0],  // Top-left corner
      [cols - 1, 0],  // Top-right corner
      [0, rows - 1],  // Bottom-left corner
      [cols - 1, rows - 1]  // Bottom-right corner
  ];

  const transformedCorners = corners.map(([x, y]) =>
      rotateCoordinate({ x, y }, rows, cols, degree)
  );

  const newXs = transformedCorners.map(position => position.x);
  const newYs = transformedCorners.map(position => position.y);

  const rotatedRows = Math.max(...newYs) - Math.min(...newYs) + 1;
  const rotatedCols = Math.max(...newXs) - Math.min(...newXs) + 1;

  // Rotate grid
  const rotatedGrid: Tile[][] = Array.from({ length: rotatedRows }, () => Array(rotatedCols).fill(null));

  for (let y = 0; y < rows; y++) {
      for (let x = 0; x < cols; x++) {
          // (x, y) after rotating becomes rotated_coordinate
          const rotatedCoordinate = rotateCoordinate({ x, y }, rows, cols, degree);
          rotatedGrid[rotatedCoordinate.y][rotatedCoordinate.x] = piece.grid[y][x];
      }
  }

  // Rotate connections
  const rotatedConnections: Connection[] = [];
  for (const connection of piece.connections) {
      const tiles: Position[] = [];
      for (const position of connection.tiles) {
          // (x, y) after rotating becomes rotated_coordinate
          const rotatedCoordinate = rotateCoordinate(position, rows, cols, degree);
          tiles.push(rotatedCoordinate);
      }
      rotatedConnections.push({ tiles });
  }

  return { ...piece, grid: rotatedGrid, connections: rotatedConnections };
}

export const transformPiece = (piece: Piece, orientation: Orientation, degree: Rotation) => {
  piece = flipPiece(piece, orientation)
  piece = rotatePiece(piece, degree)
  return piece
}

export const getPieceSize = (piece: Piece) => {
  let size = 0
  for (let row of piece.grid) {
    for (let cell of row) {
      if (cell.exists) {
        size += 1
      }
    }
  }
  return size
}