import { Component, createEffect, createMemo, createSignal, For, onCleanup, Show } from 'solid-js';
import "./Board.scss"
import { Board as BoardType, Connection, ConnectionMapping, PiecePlacement, Piece as PieceType, Position, Possibility, Tile as TileType } from '@/helpers/types';
import { getPositionId } from '@/helpers/helpers';
import { Tile } from '../Tile/Tile';
import { Piece } from '../Piece/Piece';

interface BoardProps {
  board: BoardType
  piecePlacements: PiecePlacement[]
  possibilities: Possibility[]
  onBoardCellSelect?: (position: Position) => void
  onPossibilitySelect?: (position: Position) => void
}

const computeTileSize = (rows: number, cols: number) => {
  const newSize =
    window.innerWidth < 1280
      ? Math.min(
        (window.innerWidth - 120) / cols,
        (window.innerHeight - 180) / rows
      )
      : Math.min(
        (window.innerWidth - 120 - 640) / cols,
        (window.innerHeight - 180) / rows
      );
  return Math.floor(
    Math.max(25, Math.min(100 + Math.max(rows, cols) * 2, newSize))
  );
}

export const Board: Component<BoardProps> = (props) => {
  /* Variables */
  const [unitSize, setUnitSize] = createSignal(0)

  /* Computed variables */
  const rows = createMemo(() => props.board.grid.length)
  const cols = createMemo(() => props.board.grid[0].length)
  const connectionMappings = createMemo(() => {
    const mappings: ConnectionMapping = {}
    let runningConnectionId = 0
    for (let connection of props.board.connections) {
      for (let tile of connection.tiles) {
        const tilePositionId = getPositionId(tile.x, tile.y)
        mappings[tilePositionId] = { connectionId: `${runningConnectionId}`, connection }
      }
      runningConnectionId += 1
    }
    return mappings
  })

  // Init
  createEffect(() => {
    // Recompute unitSize
    setUnitSize(computeTileSize(rows(), cols()))

    console.log("createEffect -> setUnitSize")
  })

  /* Setup resizer */
  const resizeHandler = () => {
    setUnitSize(computeTileSize(rows(), cols()))
  };
  window.addEventListener('resize', resizeHandler);
  resizeHandler();
  onCleanup(() => {
    window.removeEventListener('resize', resizeHandler);
  });

  return (
    <div class="w-fit h-fit grid-border border-4 p-4 rounded-xl border-primary/10">

      <div class='relative' style={`width: ${unitSize() * cols()}px; height: ${unitSize() * rows()}px; min-width: ${unitSize() * cols()}px; min-height: ${unitSize() * rows()}px; font-size: ${unitSize()}px`}>
        {/* Board Tiles */}
        <div class='grid justify-center content-center absolute inset-0' style={`grid-template-columns: repeat(${cols()}, ${unitSize()}px); grid-template-rows: repeat(${rows()}, ${unitSize()}px);`}>
          <For each={props.board.grid}>
            {(gridRow, yPos) => (
              <For each={gridRow}>
                {(cell, xPos) => {
                  const tilePositionId = getPositionId(xPos(), yPos())
                  if (!cell.exists) return <></>
                  return (
                    <div onClick={() => props.onBoardCellSelect?.({x: xPos(), y: yPos()})}>
                      <Tile
                        tile={cell}
                        pos={{x: xPos(), y: yPos()}}
                        grid={{size: {rows: rows(), cols: cols()}}}
                        connection={connectionMappings()[tilePositionId]?.connection}
                        depression={0}
                      >
                      </Tile>
                    </div>
                    
                  )
                }}
              </For>
            )}
          </For>
        </div>

        {/* Pieces */}
        <div>
          <For each={props.piecePlacements}>
            {(piecePlacement, index) => {
              return (
                <div class='absolute' style={`top: ${piecePlacement.position.y}em; left: ${piecePlacement.position.x}em`}>
                  <Piece
                    unitSize={unitSize()}
                    piece={piecePlacement.piece}
                    flip={piecePlacement.flip}
                    rotate={piecePlacement.rotate}
                  >
                  </Piece>
                </div>
              )
            }}
          </For>
        </div>

        {/* Higlight cells */}
        <Show when={props.possibilities}>
          <div>
            <For each={props.possibilities}>
              {(possibility, _) => {
                const {position, tile} = possibility
                return (
                  <div class='absolute cursor-pointer' style={`left: ${position.x}em; top: ${position.y}em;`}
                  onClick={() => props.onPossibilitySelect?.(position)}
                  >
                    <Tile
                      tile={tile}
                      pos={{x: position.x, y: position.y}}
                      grid={{size: {rows: rows(), cols: cols()}}}
                    >
                    </Tile>
                  </div>
                )
              }}
            </For>
          </div>
        </Show>
      </div>
    </div>
  )
}