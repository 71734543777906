import { getPositionId } from "@/helpers/helpers";
import { Connection, TileStyle, Tile as TileType} from "@/helpers/types";
import { Component, createMemo, For, mergeProps, Show } from "solid-js";

export type TileProps = {
  tile: TileType  // Has data on how to style the tile
  pos: {x: number, y: number},  // Where the tile is placed on the grid
  grid: {size: {rows: number, cols: number}},  // Info about the grid
  connection?: Connection  // The connections in the grid to which this tile belongs
  elevation?: number;
  depression?: number;
}

type TileBreakupStyle = {
  top: number; right: number; bottom: number; left: number;
  width: number; height: number;
  color: string; colorOpacity: number;
  borderColor: string,
  borderTopWidth: number, borderRightWidth: number, borderBottomWidth: number, borderLeftWidth: number,
  borderTopLeftRadius: number; borderTopRightRadius: number; borderBottomLeftRadius: number; borderBottomRightRadius: number
}

const getTileBreakup = (connectionSet: Set<string>, rows: number, cols: number, x: number, y: number, tile: TileType): TileBreakupStyle[] => {
  const getAbsPos = (relativeX: number, relativeY: number): [number, number] => {
    return [(x - 1) + relativeX, (y - 1) + relativeY]
  }

  if (!tile.exists) return []

  // Constants
  const {gap, color, colorOpacity, borderRadius, borderWidth, borderColor} = tile.style

  let centerTile: TileBreakupStyle = {
    top: gap, bottom: gap, left: gap, right: gap,
    width: 1 - 2 * gap, height: 1 - 2 * gap,
    color, colorOpacity,
    borderColor,
    borderTopWidth: borderWidth, borderRightWidth: borderWidth, borderBottomWidth: borderWidth, borderLeftWidth: borderWidth,
    borderTopLeftRadius: borderRadius, borderTopRightRadius: borderRadius, borderBottomLeftRadius: borderRadius, borderBottomRightRadius: borderRadius
  }

  const matrix: (TileBreakupStyle | null)[][] = [
    [null, null, null],
    [null, centerTile, null],
    [null, null, null]
  ]
  
  // Fill up top/right/bottom/left tiles
  const permutations = [
    {orientation: "top", pos: [1, 0], 
      centerTileUpdate: {borderTopLeftRadius: 0, borderTopRightRadius: 0, borderTopWidth: 0}, 
      tile: {
        top: 0, bottom: 1 - gap, left: gap, right: gap,
        width: 1 - 2*gap, height: gap,
        color, colorOpacity,
        borderTopWidth: borderWidth, borderRightWidth: borderWidth, borderBottomWidth: 0, borderLeftWidth: borderWidth,
        borderColor,
        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0
      }
    },
    {orientation: "right", pos: [2, 1], 
      centerTileUpdate: {borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRightWidth: 0}, 
      tile: {
        top: gap, bottom: gap, left: 1 - gap, right: 0,
        width: gap, height: 1- 2 * gap,
        color, colorOpacity,
        borderColor,
        borderTopWidth: borderWidth, borderRightWidth: borderWidth, borderBottomWidth: borderWidth, borderLeftWidth: 0,
        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0
      }
    },
    {orientation: "bottom", pos: [1, 2], 
      centerTileUpdate: {borderBottomRightRadius: 0, borderBottomLeftRadius: 0, borderBottomWidth: 0}, 
      tile: {
        top: 1 - gap, bottom: 0, left: gap, right: gap,
        width: 1 - 2*gap, height: gap,
        color, colorOpacity,
        borderColor,
        borderTopWidth: 0, borderRightWidth: borderWidth, borderBottomWidth: borderWidth, borderLeftWidth: borderWidth,
        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0
      }
    },
    {orientation: "left", pos: [0, 1], 
      centerTileUpdate: {borderBottomLeftRadius: 0, borderTopLeftRadius: 0, borderLeftWidth: 0}, 
      tile: {
        top: gap, bottom: gap, left: 0, right: 1 - gap,
        width: gap, height: 1 - 2*gap,
        color, colorOpacity,
        borderColor,
        borderTopWidth: borderWidth, borderRightWidth: 0, borderBottomWidth: borderWidth, borderLeftWidth: borderWidth,
        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0
      }
    },
  ]
  for (const {orientation, pos, centerTileUpdate, tile} of permutations) {
    const [relativeX, relativeY] = pos

    const [absX, absY]: [number, number] = getAbsPos(relativeX, relativeY)
    if (absY < 0 || absY >= rows || absX < 0 || absX >= cols) {
      continue
    }

    const tilePositionId = getPositionId(absX, absY)
    if (!connectionSet.has(tilePositionId)) {
      continue
    }

    matrix[relativeY][relativeX] = tile

    // Make updates to central tile
    matrix[1][1] = {
      ...matrix[1][1],
      ...centerTileUpdate
    }
  }

  // Fill up corner tiles
  const cornerPermutations = [
    {orientation: "top-left", pos: [0, 0], 
      neighbours: [{pos: [1, 0], updates: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftWidth: 0}}, {pos: [0, 1], updates: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopWidth: 0}}], 
      tile: {
        top: 0, bottom: 1 - gap, left: 0, right: 1 - gap,
        width: gap, height: gap,
        color, colorOpacity,
        borderColor,
        borderTopWidth: borderWidth, borderRightWidth: 0, borderBottomWidth: 0, borderLeftWidth: borderWidth,
        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0
      }
    },
    {orientation: "top-right", pos: [2, 0], 
      neighbours: [{pos: [1, 0], updates: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderRightWidth: 0}}, {pos: [2, 1], updates: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopWidth: 0}}], 
      tile: {
        top: 0, bottom: 1 - gap, left: 1 - gap, right: 0,
        width: gap, height: gap,
        color, colorOpacity,
        borderColor,
        borderTopWidth: borderWidth, borderRightWidth: borderWidth, borderBottomWidth: 0, borderLeftWidth: 0,
        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0
      }
    },
    {orientation: "bottom-right", pos: [2, 2], 
      neighbours: [{pos: [2, 1], updates: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderBottomWidth: 0}}, {pos: [1, 2], updates: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderRightWidth: 0}}], 
      tile: {
        top: 1 - gap, bottom: 0, left: 1 - gap, right: 0,
        width: gap, height: gap,
        color, colorOpacity,
        borderColor,
        borderTopWidth: 0, borderRightWidth: borderWidth, borderBottomWidth: borderWidth, borderLeftWidth: 0,
        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0
      }
    },
    {orientation: "bottom-left", pos: [0, 2], 
      neighbours: [{pos: [1, 2], updates: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderLeftWidth: 0}}, {pos: [0, 1], updates: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderBottomWidth: 0}}], 
      tile: {
        top: 1 - gap, bottom: 0, left: 0, right: 1- gap,
        width: gap, height: gap,
        color, colorOpacity,
        borderColor,
        borderTopWidth: 0, borderRightWidth: 0, borderBottomWidth: borderWidth, borderLeftWidth: borderWidth,
        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0
      }
    }
  ]
  for (const {orientation, pos, neighbours, tile} of cornerPermutations) {
    const [relativeX, relativeY] = pos

    const [absX, absY]: [number, number] = getAbsPos(relativeX, relativeY)
    if (absY < 0 || absY >= rows || absX < 0 || absX >= cols) {
      continue
    }

    const tilePositionId = getPositionId(absX, absY)

    if (!connectionSet.has(tilePositionId)) {
      continue
    }

    let connected = true
    for (let neighbour of neighbours) {
      if (!matrix[neighbour.pos[1]][neighbour.pos[0]]) {
        connected = false
        break
      }
    }
    if (!connected) {
      continue
    }

    matrix[relativeY][relativeX] = tile

    // Make updates to neighbours properties
    for (let neighbour of neighbours) {
      const pos = neighbour.pos
      matrix[pos[1]][pos[0]] = {
        ...matrix[pos[1]][pos[0]],
        ...neighbour.updates
      }
    }
  }

  // TODO: Optimise matrix

  // Return tiles
  const tiles = matrix.flat().flat().filter((x) => !!x)
  return tiles
}

export const Tile: Component<TileProps> = (_props) => {
  const props = mergeProps({elevation: 0, depression: -1 }, _props);

  /* Computed variables */ 
  const connectionSet = createMemo(() => {
    return new Set((props.connection?.tiles || []).map((tilePos) => getPositionId(tilePos.x, tilePos.y)))
  })

  const styles = createMemo(() => {
    return getTileBreakup(connectionSet(), props.grid.size.rows, props.grid.size.cols, props.pos.x, props.pos.y, props.tile)
  })

  return (
    <div class='relative' style={`width: 1em; height: 1em; transform: scale(1); z-index: ${props.tile.exists ? props.elevation : props.depression}`}>
      <Show when={props.tile.exists}>
        <For each={styles()}>
          {(style) => (
            <div
              class="absolute p-0 shadow-none min-h-0 border-0"
              style={`font-size: 1em; 
                top: ${style.top}em; right: ${style.right}em; bottom: ${style.bottom}em; left: ${style.left}em;
                width: ${style.width}em; height: ${style.height}em; 
                border-top-width: ${style.borderTopWidth}em;
                border-right-width: ${style.borderRightWidth}em;
                border-bottom-width: ${style.borderBottomWidth}em;
                border-left-width: ${style.borderLeftWidth}em;
                border-top-left-radius: ${style.borderTopLeftRadius}em; 
                border-top-right-radius: ${style.borderTopRightRadius}em; 
                border-bottom-left-radius: ${style.borderBottomLeftRadius}em; 
                border-bottom-right-radius: ${style.borderBottomRightRadius}em;
                border-color: ${style.borderColor};
                background-color: ${style.color};
                opacity: ${style.colorOpacity};
              `}
            >
            </div>
          )}
        </For>
      </Show>
    </div>
  )
}