import { Component, createEffect, createMemo, createSignal, lazy, Match, Ref, Show, splitProps, Suspense, Switch } from 'solid-js';
import './GameLogic.scss';
import { Board } from "../../components/Board/Board"
import { Board as BoardType, Piece, Position, Tile as TileType, Game as GameType, Possibility, Player, PiecePlacement } from '@/helpers/types';
import { Tray } from '@/components/Tray/Tray';
import { deepClone } from '@polyomino/ts-utils/src/lib/utils';
import { ApiLoadInfo, ApiState } from '@polyomino/ts-utils/src/lib/types';
import { toaster } from "@kobalte/core";
import {
  Toast,
  ToastContent,
  ToastDescription,
  ToastProgress,
  ToastTitle
} from "@/components/ui/toast";
import { GameRequest, GetPossibilitiesRequest, MakeMoveRequest, PingRequest, StartNewGameRequest } from './GameRequest';
import { GameResponse } from './GameResponse';
import { getPieceSize } from '@/helpers/helpers';
import { Button } from '@/components/ui/button';
import { Settings } from 'lucide-solid';
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { DialogTriggerProps } from '@kobalte/core/dialog';
// import { MonacoEditor } from 'solid-monaco';
import * as changeKeys from "change-case/keys";


const LazyMonacoEditor = lazy(() =>
  import('solid-monaco').then((module) => ({ default: module.MonacoEditor }))
)

export type GameLogicProps = {
  ref: Ref<any>;
  sendMessage: (message: GameRequest) => void;
  settingsSchema?: any
  defaultSettings?: any
}


export const GameLogic: Component<GameLogicProps> = (props) => {
  /* Variables */
  // const [{sendMessage: _sendMessage}, rest] = splitProps(props, ['sendMessage'])
  const [settingsStr, setSettingsStr] = createSignal<string>("")
  const [game, setGame] = createSignal<GameType>()
  const [monacoRef, setMonacoRef] = createSignal<any>()
  const [showConfigEditor, setShowConfigEditor] = createSignal<boolean>(false)

  const [selectedPiece, setSelectedPiece] = createSignal(null);
  const [selectedPositions, setSelectedPositions] = createSignal<Position[]>([])
  const [possibilities, setPossibilities] = createSignal<Possibility[]>([])
  const [finalPlacement, setFinalPlacement] = createSignal<PiecePlacement | null>(null)

  /* Computed Variables */
  const gridSize = createMemo<{ width: number, height: number }>(() => {
    if (!game()) return { width: 0, height: 0 }
    return { width: game().board.grid[0].length, height: game().board.grid.length }
  })

  const isGameEnded = createMemo<boolean>(() => {
    if (!game()) return false
    return game().ended
  })

  const player1 = createMemo<Player>(() => {
    if (!game()) return null
    return game().players[0]
  })

  const player2 = createMemo<Player>(() => {
    if (!game()) return null
    return game().players[1]
  })

  const player1RemainingPieces = createMemo<Piece[]>(() => {
    if (!game()) return []
    if (!player1()) return []
    return player1().pieces.filter((piece) => !usedUpPieceIds().has(piece.id))
  })

 
  const player2RemainingPieces = createMemo<Piece[]>(() => {
    if (!game()) return []
    if (!player2()) return []
    return player2().pieces.filter((piece) => !usedUpPieceIds().has(piece.id))
  })


  const myPlayers = createMemo<Player[]>(() => {
    if (!game()) return null
    return game().players.filter((x) => x.type === "human")
  })

  const myPlayersId = createMemo<string[]>(() => {
    if (!game()) return null
    return myPlayers().map((x) => x.id)
  })

  const isMyTurn = createMemo<boolean>(() => {
    if (!game()) return false
    return !isGameEnded() && myPlayersId().includes(game().currentTurn)
  })

  const usedUpPieceIds = createMemo<Set<string>>(() => {
    if (!game()) return new Set()
    return new Set(game().piecePlacements.map((x) => x.piece.id))
  })

  /* Helpers */
  const sendMessage = (message: GameRequest) => {
    console.log(`Sending message`, message)
    props.sendMessage(message)
  }

  const resetSelectedPiece = () => {
    setSelectedPiece(null)
    setSelectedPositions([])
    setPossibilities([])
    setFinalPlacement(null)
  }

  const triggerGetPossibilities = () => {
    const message: GetPossibilitiesRequest = {
      id: crypto.randomUUID(),
      type: "GET_POSSIBILITIES",
      data: {
        gameId: game().id,
        selectedPiece: selectedPiece(),
        selectedPositions: selectedPositions()
      }
    }
    sendMessage(message)
  }

  const triggerMakeMove = () => {
    const message: MakeMoveRequest = {
      id: crypto.randomUUID(),
      type: "MAKE_MOVE",
      data: {
        gameId: game().id,
        piecePlacement: finalPlacement()
      }
    }
    sendMessage(message)
  }

  const setJsonSchemaToEditor = () => {
    if (!monacoRef() || !props.settingsSchema || !props.defaultSettings) {
      return
    }

    const defaultSettings = changeKeys.snakeCase(props.defaultSettings)
    setSettingsStr(JSON.stringify(defaultSettings!, null, 2))

    monacoRef().languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          uri: "http://game/settings-schema.json", // id of the first schema
          fileMatch: ["*"], // associate with our model
          schema: props.settingsSchema!
        },
      ]
    });
  }

  /* Event handlers */
  const startNewGameFromConfig = () => {
    let settings = null
    try {
      settings = JSON.parse(settingsStr())
      settings = changeKeys.snakeCase(settings)
    }
    catch (error) {
      toaster.show(props => (
        <Toast toastId={props.toastId}>
          <ToastContent>
            <ToastTitle>Error in config</ToastTitle>
            <ToastDescription>{error.toString()}</ToastDescription>
          </ToastContent>
          <ToastProgress />
        </Toast>
      ))
      throw error
    }

    setGame(null)
    sendMessage({
      id: crypto.randomUUID(),
      type: "START_NEW_GAME",
      data: {settings}
    } as StartNewGameRequest)
    setShowConfigEditor(false)
  }

  const handleMessage = (response: GameResponse) => {
    if (response.result) {
      console.log("Incoming message", response)
    }
    else {
      console.error("Incoming message", response)
    }

    if (response.result === "error") {
      toaster.show(props => (
        <Toast toastId={props.toastId}>
          <ToastContent>
            <ToastTitle>Error in {response.type} action</ToastTitle>
            <ToastDescription>{(response as any).data.message}</ToastDescription>
          </ToastContent>
          <ToastProgress />
        </Toast>
      ))
      return
    }
    

    switch (response.type) {
      case "START_NEW_GAME": {
        setGame(response.data.game)
        break
      }
      case "GET_POSSIBILITIES": {
        setPossibilities(response.data.possibilities)
        setFinalPlacement(response.data.finalPlacement)
        break
      }
      case "MAKE_MOVE": {
        resetSelectedPiece()
        setGame(response.data.game)
        break
      }
      case "MAKE_AI_MOVE": {
        setGame(response.data.game)
        break
      }
    }
  }

  const onEditorMount = (monaco, editor) => {
    console.log(monaco, editor)
    setMonacoRef(monaco)
  }

  const selectPiece = (piece: Piece) => {
    resetSelectedPiece()
    setSelectedPiece(piece)
    triggerGetPossibilities()
  }

  const onPossibilitySelect = (position: Position) => {
    console.log("onPossibilitySelect", position)
    if (selectedPositions().find((pos) => pos.x === position.x && pos.y === position.y)) {
      setSelectedPositions(
        selectedPositions().filter((pos) => !(pos.x === position.x && pos.y === position.y))
      )
    }
    else {
      setSelectedPositions(
        selectedPositions().concat([position])
      )
    }
    triggerGetPossibilities()
  }

  const onBoardCellSelect = (position: Position) => {
    if (!selectedPiece()) {
      return
    }
    setSelectedPositions([position])
    triggerGetPossibilities()
  }

  const resetTurn = () => {
    selectPiece(selectedPiece())
  }

  const makeMove = () => {
    triggerMakeMove()
  }

  createEffect(() => {
    setJsonSchemaToEditor()
  })

  /* Init */
  props.ref({
    handleMessage: handleMessage
  })

  sendMessage({
    id: crypto.randomUUID(),
    type: "PING",
    data: undefined
  } as PingRequest)

  // Start new game
  sendMessage({
    id: crypto.randomUUID(),
    type: "START_NEW_GAME",
    data: {}
  } as StartNewGameRequest)

  return <>
    <Show when={!!game()}>
      <div class={`${false ? 'disabled' : ''} w-full h-full bg-gradient-to-b from-gray-900 to-gray-950 flex flex-col overflow-hidden`}>
        {/* Header */}
        <Show when={false}>
          <div class='px-8 py-2 shadow-md text-center'>
            <h1 class='text-3xl font-bold mb-8 text-gray-100 tracking-wide' style="margin: 0">Polyomino</h1>
          </div>
        </Show>
        
        {/* Body */}
        <div class="grow overflow-auto">
          <div class='flex flex-1 h-full justify-center items-center flex-wrap xl:flex-nowrap gap-2 pt-8 pb-32 xl:pt-0 xl:pb-0'>
            {/* Player 1 tray */}
            <Show when={player1()}>
              <div class='flex flex-col p-4 gap-4 text-neutral-content self-stretch h-full w-full max-w-64'>
                <div class={`text-center ${player1().id === game().winner ? 'text-green-600' : player1().id === game().currentTurn ? 'text-blue-600' : 'text-white'}`}>Score: {player1().score}
                  <Show when={player1().id === game().winner}>
                    <span class='ml-1'>(Winner)</span>
                  </Show>
                </div>
                <div class='grow overflow-auto'>
                  <Tray disable={!isMyTurn() || game().currentTurn !== player1().id} orientation='vertical' alignment='left' pieces={player1RemainingPieces()} selectedPiece={selectedPiece()} onPieceSelect={(piece) => selectPiece(piece)} />
                </div>
              </div>
            </Show>
            

            {/* Board */}
            <div class='flex flex-col shrink self-stretch px-8 h-full'>
              <div class="flex justify-between items-center gap-4 pt-3 pb-2">
                <div>
                <Tooltip>
                  <TooltipTrigger>
                  <Button variant="ghost" size='icon' disabled={!props.settingsSchema} onClick={() => setShowConfigEditor(true)}>
                    <Settings class='text-white hover:text-black' />
                  </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>New game from config {!props.settingsSchema ? '(Loading...)' : ''}</p>
                  </TooltipContent>
                </Tooltip>
                </div>
                <div class="flex gap-4 grow justify-center">
                  <Button
                    variant="outline"
                    disabled={!isMyTurn() || selectedPositions().length === 0}
                    onClick={resetTurn}
                  >
                    Reset
                  </Button>
                  <Button
                    variant="outline"
                    disabled={!isMyTurn() || !finalPlacement()}
                    onClick={makeMove}
                  >
                    Save
                  </Button>
                </div>
              </div>

              <div class='grow overflow-auto py-8 pb-16 xl:py-0 flex justify-center'>
                {/* <div class='flex items-center justify-center m-0 p-0 border-0 min-h-full min-w-full h-fit w-fit'> */}
                <Board
                  board={game().board}
                  piecePlacements={game().piecePlacements}
                  possibilities={possibilities()}
                  onBoardCellSelect={onBoardCellSelect}
                  onPossibilitySelect={onPossibilitySelect}
                ></Board>
                {/* </div> */}
              </div>
            </div>

            {/* Player 2 tray */}
            <Show when={player2()}>
              <div class='flex flex-col p-4 gap-4 text-neutral-content self-stretch h-full w-full max-w-64'>
                <div class={`text-center ${player2().id === game().winner ? 'text-green-600' : player2().id === game().currentTurn ? 'text-blue-600' : 'text-white '}`}>Score: {player2().score}
                  <Show when={player2().id === game().winner}>
                    <span class='ml-1'>(Winner)</span>
                  </Show>
                </div>
                <div class='grow overflow-auto'>
                  <Tray disable={!isMyTurn() || game().currentTurn !== player2().id} orientation='vertical' alignment='right' pieces={player2RemainingPieces()} selectedPiece={selectedPiece()} onPieceSelect={(piece) => selectPiece(piece)} />
                </div>
              </div>
            </Show>

          </div>
        </div>
      </div>
    </Show>

    {/* Config editor */}
    <Dialog open={showConfigEditor()} onOpenChange={setShowConfigEditor}>
      <DialogContent class="min-w-fit">
        <div class='flex flex-col gap-4' style="height: 90vh; min-height: 100px; width: 60vw; min-width: 200px;">
        <div>
            <Button variant="default" onClick={startNewGameFromConfig}>
              Start new game
            </Button>
          </div>
          <div class='grow'>
          <Suspense fallback={<div>Loading editor...</div>}>
            <LazyMonacoEditor 
                class="h-full w-full" 
                language="json" 
                value={settingsStr()} 
                onChange={setSettingsStr} 
                onMount={onEditorMount}
            />;
          </Suspense>
          </div>        
        </div>
      </DialogContent>
    </Dialog>

  </>
};
