
import { Component, createMemo, createSignal, Match, onCleanup, Show, Switch } from 'solid-js';
import {
  AlertDialog,
  AlertDialogClose,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertDialogAction
} from "@/components/ui/alert-dialog";

import './Game.scss';
import { formUrl, sleep } from '@polyomino/ts-utils/src/lib/utils';
import { ApiLoadInfo, ApiState } from "@polyomino/ts-utils/src/lib/types"
import { BACKEND_HTTP_BASE_URL, BACKEND_SOCKET_BASE_URL } from '@/helpers/constants';
import PageLoader from '@/components/PageLoader/PageLoader';
import { Reconnecting } from './Reconnecting';
import { GameLogic } from './GameLogic';
import { makeGetCall } from "@polyomino/ts-utils/src/lib/axios-utils"
import { toaster } from "@kobalte/core";
import {
  Toast,
  ToastContent,
  ToastDescription,
  ToastProgress,
  ToastTitle
} from "@/components/ui/toast";

export const Game: Component = () => {
  // Socket helpers
  const sendMessage = (message: any) => {
    if (socket() && socket().readyState === socket().OPEN) {
      // console.log('send message to server', message)
      // console.log("\n")
      socket().send(JSON.stringify(message))
    }
  }

  const onSocketOpen = (event: Event) => {
    console.log('Connected to WebSocket server', event);
    setSocketLoadInfo({ state: ApiState.LOADED })
    setPageInit(true)
    setSocketRetryCount(0)
  }

  const onSocketMessage = (event: any) => {
    const message = JSON.parse(event.data)
    handleIncomingMessage(message)
  }

  const onSocketError = (error: Event) => {
    console.error('WebSocket error', error);
  }

  const onSocketClose = async () => {
    if (socketRetryCount() < 3 && !(socketLoadInfo().error?.status || 500).toString().startsWith("4")) {
      return setupSocket(true)
    }

    if (socketLoadInfo().state !== ApiState.ERROR) {
      setSocketLoadInfo({
        state: ApiState.ERROR, error: {
          status: 500,
          code: "server_error",
          message: "Internal server error"
        }
      })
    }
    // If closed by app purposefully, show a modal with error message and two options - try again, go to dashboard
    // if closed due to unknown reason
    //   - if page not loaded -> Show modal with error mesage adn two optios - try again and go to dashboard
    //   - if page loaded
    //        - show reconnecting UI and try for 3 more times
    //        - if already tried 3 times, show a modal with error message and two options - try again and go to dashboard
    console.log('Disconnected from WebSocket server', event);
  }

  const closeSocket = () => {
    if (!socket()) return
  
    if (socket().readyState === WebSocket.OPEN) {
      socket().close()
    }

    socket().removeEventListener("open", onSocketOpen)
    socket().removeEventListener("message", onSocketMessage)
    socket().removeEventListener("error", onSocketError)
    socket().removeEventListener("close", onSocketClose)
  }
  
  const setupSocket = async (retry = false) => {
    // TODO: Take care of case when error / close events for an older socket fires when new socket is created
    closeSocket()

    setSocketRetryCount(socketRetryCount() + 1)
    setSocketLoadInfo({ state: ApiState.LOADING })
    if (retry) {
      await sleep(1000)
    }
    const _socket = new WebSocket(formUrl({ basePath: BACKEND_SOCKET_BASE_URL, otherPath: "/game/ws", params: {} }))
    setSocket(_socket)

    // Add listeners
    _socket.onopen = onSocketOpen
    _socket.onmessage = onSocketMessage
    _socket.onerror = onSocketError
    _socket.onclose = onSocketClose
  }

  const reconnectSocket = () => {
    setSocketRetryCount(0)
    setupSocket()
  }
  
  const handleIncomingMessage = (message: any) => {
    const { type, data } = message
    gameLogicRef?.handleMessage(message)  // Invoke child component handler function
  }

  const loadSettingsSchema = async () => {
    if ([ApiState.LOADED, ApiState.LOADED].includes(settingsSchemaLoadInfo().state)) {
      return
    }

    setSettingsSchemaLoadInfo({state: ApiState.LOADING})
    const url = formUrl({ basePath: BACKEND_HTTP_BASE_URL, otherPath: "/game/schema"})
    const { response, error } = await makeGetCall(url)
    if (error) {
      setSettingsSchemaLoadInfo({state: ApiState.ERROR, error: error})
      toaster.show(props => (
        <Toast toastId={props.toastId}>
          <ToastContent>
            <ToastTitle>Failed to load settings schema</ToastTitle>
            <ToastDescription>{error.message}</ToastDescription>
          </ToastContent>
          <ToastProgress />
        </Toast>
      ))
      return
    }
    
    setSettingsSchemaLoadInfo({state: ApiState.LOADED})
    setSettingsSchema(JSON.parse(response.data.schema))
    console.log("Final schema", settingsSchema())
    setDefaultSettings(response.data.defaultSettings)
  }
  
  /* Variables */
  const [settingsSchemaLoadInfo, setSettingsSchemaLoadInfo] = createSignal<ApiLoadInfo>({state: ApiState.NOT_LOADED})
  const [settingsSchema, setSettingsSchema] = createSignal<any>(null)
  const [defaultSettings, setDefaultSettings] = createSignal<any>(null)
  const [socket, setSocket] = createSignal<WebSocket>()
  const [pageInit, setPageInit] = createSignal(false);
  const [socketLoadInfo, setSocketLoadInfo] = createSignal<ApiLoadInfo>({state: ApiState.NOT_LOADED})
  const [socketRetryCount, setSocketRetryCount] = createSignal<number>(0)
  let gameLogicRef: any;

  /* Computed Variables */
  const pageLoadApiInfo = createMemo(() => {
    if (!pageInit()) return socketLoadInfo()
    return {state: ApiState.LOADED}
  })

  /* Init */
  setupSocket()
  loadSettingsSchema()

  /* Clean up */
  onCleanup(() => closeSocket())
  
  return (
    <>
      <Switch>
        <Match when={pageLoadApiInfo().state === ApiState.LOADING}>
          <PageLoader />
        </Match>
        {/* <Match when={pageLoadApiInfo().state === ApiState.ERROR}>
          <div class="text-red-600">{pageLoadApiInfo().error?.message}</div>
        </Match> */}
        <Match when={pageLoadApiInfo().state === ApiState.LOADED}>

          {/* Show game here */}
          {/* Inside Show so that the component gets re-rendered when socket connection refreshes */}
          <Show when={socketLoadInfo().state === ApiState.LOADED}>
            <GameLogic ref={gameLogicRef} sendMessage={sendMessage} settingsSchema={settingsSchema()} defaultSettings={defaultSettings()}></GameLogic>
          </Show>

          {/* Reconnecting */}
          <Show when={socketLoadInfo().state === ApiState.LOADING}>
            <AlertDialog open={socketLoadInfo().state === ApiState.LOADING}>
              <AlertDialogContent style="box-shadow: none; border: none; background: none; outline: none" class="flex justify-center">
                <Reconnecting />
              </AlertDialogContent>
            </AlertDialog>
          </Show>

        </Match>
      </Switch>


      {/* Error dialog */}
      <Show when={socketLoadInfo().state === ApiState.ERROR}>
        <AlertDialog open={socketLoadInfo().state === ApiState.ERROR}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Failed to connect</AlertDialogTitle>
              <AlertDialogDescription>
                {socketLoadInfo().error.message}
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogClose onClick={() => reconnectSocket()}>Try again</AlertDialogClose>
              <AlertDialogAction onClick={() => window.location.href = "/"}>Go to dashboard</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </Show>
    </>
  )
};
