import { Component, createSignal, For, mergeProps } from 'solid-js';
import "./Tray.scss";
import { Piece as PieceType } from '@/helpers/types';
import { Piece } from '../Piece/Piece';

export interface TrayProps {
  disable?: boolean;
  orientation: "horizontal" | "vertical";
  alignment: "top" | "right" | "left" | "bottom";
  pieces: PieceType[];
  selectedPiece?: PieceType;
  onPieceSelect?: (piece: PieceType) => void;
}

export const Tray: Component<TrayProps> = (_props) => {
  const unitSize = 18;
  const props = mergeProps({ disable: false }, _props);

  return (
    <div class="p-4 rounded-2xl bg-gray-800/90 backdrop-blur-sm shadow-lg h-full">
      <div
        class="flex flex-col gap-3 flex-wrap max-h-full overflow-y-auto"
      >
        <For each={props.pieces}>
          {(piece) => (
            <div 
            onClick={() => !props.disable && props.onPieceSelect?.(piece)}
            class={`piece-holder p-1 flex items-center justify-center rounded-xl ${props.disable ? 'disabled' : ''} ${props.selectedPiece?.id === piece.id ? 'selected' : ''}`}
            style={`min-height: ${4*unitSize + 4}px; min-width: ${4*unitSize + 4}px;`}
          >
              <Piece unitSize={unitSize} piece={piece} />
            </div>
          )}
        </For>
      </div>
    </div>
  );
};
