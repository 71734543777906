import { Component, createMemo, For, mergeProps } from 'solid-js';
import "./Piece.scss"
import { Connection, ConnectionMapping, Orientation, Piece as PieceType, Rotation } from '@/helpers/types';
import { Tile, TileProps } from '../Tile/Tile';
import { getPositionId, transformPiece } from '@/helpers/helpers';

export interface PieceProps {
  unitSize: number;
  piece: PieceType;
  flip?: Orientation;
  rotate?: Rotation;
  elevation?: number;
  depression?: number;
}

export const Piece: Component<PieceProps> = (_props) => {
  const props = mergeProps({flip: "v" as Orientation, rotate: 0 as Rotation}, _props)

  /* Computed variables */
  const tPiece = createMemo(() => {
    return transformPiece(props.piece, props.flip, props.rotate)  
  })

  const rows = createMemo(() => tPiece().grid.length)
  const cols = createMemo(() => tPiece().grid[0].length)

  const connectionMappings = createMemo(() => {
    const mappings: ConnectionMapping = {}
    let runningConnectionId = 0
    for (let connection of tPiece().connections) {
      for (let tile of connection.tiles) {
        const tilePositionId = getPositionId(tile.x, tile.y)
        mappings[tilePositionId] = { connectionId: `${runningConnectionId}`, connection }
      }
      runningConnectionId += 1
    }
    return mappings
  })

  return (
    <div class='relative grid justify-center content-center inset-0' style={`grid-template-columns: repeat(${cols()}, ${props.unitSize}px); grid-template-rows: repeat(${rows()}, ${props.unitSize}px); font-size: ${props.unitSize}px;`}>
      <For each={tPiece().grid}>
      {(gridRow, yPos) => (
        <For each={gridRow}>
          {(cell, xPos) => {
            const tilePositionId = getPositionId(xPos(), yPos())
            return (
              <Tile
                tile={cell}
                pos={{x: xPos(), y: yPos()}}
                grid={{size: {rows: rows(), cols: cols()}}}
                connection={connectionMappings()[tilePositionId]?.connection}
                elevation={props.elevation}
                depression={props.depression}
              >
              </Tile>
            )
          }}
        </For>
      )}
    </For>
    </div>
    
  )
}